<template>
	<div v-loading="loading || detailLoading">
		<div class="page-sub-title">
			基本信息
		</div>
		<div class="flex-form flex flex-wrap">
			<div class="form-item flex flex-center">
				<div class="form-label">
					<span class="must">*</span>
					小程序名称
				</div>
				<el-input
					class="flex-1"
					v-model="form.name"
					maxlength="32"
					size="small"
					placeholder="小程序名称后续不可修改，请勿重复"
					:disabled="!!form.id"
					@input="form.shortName = form.name"
				></el-input>
			</div>
			<div class="form-item flex flex-center">
				<div class="form-label">
					小程序简称
				</div>
				<el-input
					class="flex-1"
					v-model="form.shortName"
					maxlength="10"
					show-word-limit
					size="small"
					placeholder="请填写小程序简称"
				></el-input>
			</div>
			<div class="form-item">
				<div class="flex flex-center mb-16">
					<div class="form-label">
						<span class="must">*</span>
						所属机构
					</div>
					<el-cascader
						class="flex-1"
						placeholder="请选择所属机构"
						size="small"
						:options="options"
						:props="props"
						filterable
						v-model="chooseOrgList"
						@change="handleChangeOrg"
					>
						<template slot-scope="{ data }">
							<el-tooltip
								:content="data.orgType == 2 ? '集团中所有门店都已关联小程序，不可选择' : '门店已关联小程序，不可选择'"
								:disabled="!data.disable"
								placement="right"
							>
								<span>{{ data.hotelName }}</span>
							</el-tooltip>
						</template>
					</el-cascader>
				</div>
				<div class="flex flex-center mb-16">
					<div class="form-label">
						<span class="must">*</span>
						是否测试小程序
						<el-tooltip
							content="用于区分客户正式小程序以及内部人员测试小程序，开户时请仔细选择，后续不允许修改"
							placement="bottom"
						>
							<span class="icon-warning-outline"></span>
						</el-tooltip>
					</div>
					<el-select class="flex-1" v-model="form.testFlag" placeholder="请选择" size="small" :disabled="!!form.id">
						<el-option label="正式小程序" :value="false"></el-option>
						<el-option label="测试小程序" :value="true"></el-option>
					</el-select>
				</div>
				<div class="flex flex-center mb-16">
					<div class="form-label">
						<span class="must">*</span>
						AppId
					</div>
					<el-input class="flex-1" v-model="form.appId" size="small" placeholder="请填写小程序AppId"></el-input>
				</div>
				<div class="flex">
					<div class="form-label">
						<span class="must">*</span>
						AppSecret
					</div>
					<el-input
						class="flex-1"
						type="textarea"
						rows="2"
						v-model="form.appSecret"
						size="small"
						placeholder="请填写小程序AppSecret"
					></el-input>
				</div>
			</div>
			<div class="form-item flex self-start">
				<div class="form-label pt-5">
					<span class="must">*</span>
					关联门店
				</div>
				<div class="flex-1">
					<div
						class="hotel-item flex flex-center"
						v-for="(item, index) in form.relationHotelInfoList"
						:key="item.id"
						v-show="index < 3"
						:class="{ disabled: chooseOrgType == 1 }"
					>
						<span class="flex-1 mr-10">{{ item.hotelShortName || item.hotelName }}</span>
						<i
							class="font-size-16 el-icon-error c-a4 cursor-point"
							@click="deleteItem(index)"
							v-show="chooseOrgType == 2"
						></i>
					</div>
					<div class="flex">
						<el-button
							type="primary"
							plain
							size="small"
							class="flex-1"
							v-if="form.relationHotelInfoList.length > 3"
							@click="relationVisible = true"
							>查看全部已选门店（{{ form.relationHotelInfoList.length }}）</el-button
						>
						<el-button class="flex-1" size="small" @click="addHotelListVisible = true" v-show="chooseOrgType == 2"
							>+ 选择门店</el-button
						>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="page-sub-title">
			功能模块
		</div>
		<div class="flex-form flex flex-wrap">
			<div class="form-item flex flex-wrap" style="width: 100%">
				<el-checkbox class="mr-20" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"
					>全选</el-checkbox
				>
				<el-checkbox-group v-model="form.functionCodeList" @change="handleCheckChange">
					<el-checkbox
						ref="check"
						v-for="item in functionOption"
						:key="item.k"
						:label="item.k"
						:disabled="item.disabled"
            @change="chooseItem($event, item)"
					>
						{{ item.v }}
						<el-tooltip effect="dark" :content="item.note" placement="bottom" :disabled="!item.note">
							<i class="el-icon-question grey" v-if="item.note"></i>
						</el-tooltip>
					</el-checkbox>
				</el-checkbox-group>
			</div>
		</div> -->
		<div class="page-sub-title">
			销售信息
		</div>
		<div class="flex-form flex flex-wrap">
			<div class="form-item flex flex-center">
				<div class="form-label">
					<span class="must">*</span>
					初装费
				</div>
				<el-input
					class="flex-1"
					v-model="form.firstPrice"
					maxlength="8"
					size="small"
					placeholder="请输入初装费"
					@input="handleInput('firstPrice')"
				>
					<span slot="suffix" style="line-height: 32px">元</span>
				</el-input>
			</div>
			<!-- <div class="form-item flex flex-center">
				<div class="form-label">
					<span class="must">*</span>
					年费
				</div>
				<el-input
					class="flex-1"
					v-model="form.yearPrice"
					size="small"
					placeholder="请输入年费"
					@input="handleInput('yearPrice')"
				>
					<span slot="suffix" style="line-height: 32px">元/年</span>
				</el-input>
			</div> -->
			<div class="form-item flex flex-center">
				<div class="form-label">
					<span class="must">*</span>
					所属直销
				</div>
				<el-select
					class="flex-1"
					size="small"
					v-model="form.salerId"
					filterable
					remote
					placeholder="请选择直销人员"
					:remote-method="remoteMethod"
					:loading="selectLoading"
				>
					<el-option
						v-for="item in salerList"
						:key="item.id"
						:disabled="item.status === 2"
						:label="item.realName"
						:value="item.userId"
					>
					</el-option>
				</el-select>
			</div>
		</div>
		<div class="page-sub-title">
			系统信息
		</div>
		<div class="flex-form flex flex-wrap">
			<div class="form-item flex flex-center">
				<div class="form-label">
					<span class="must">*</span>
					状态
				</div>
				<el-select class="flex-1" v-model="form.status" placeholder="请选择" size="small">
					<el-option v-for="item in statusOption" :key="item.k" :label="item.v" :value="item.k"> </el-option>
				</el-select>
			</div>
		</div>
		<div class="flex-form flex flex-wrap">
			<div class="form-item flex flex-center">
				<div class="form-label">
					<span class="must">*</span>
					启用时间
				</div>
				<el-date-picker
					class="flex-1"
					size="small"
					v-model="form.enableTime"
					type="date"
					:picker-options="pickerOptions"
					value-format="yyyy-MM-dd"
					placeholder="选择日期"
				>
				</el-date-picker>
			</div>
			<div class="form-item flex flex-center">
				<div class="form-label">
					<span class="must">*</span>
					到期时间
				</div>
				<el-date-picker
					class="flex-1"
					size="small"
					v-model="form.expireTime"
					type="date"
					:picker-options="pickerOptions"
					value-format="yyyy-MM-dd"
					placeholder="选择日期"
					:disabled="!!form.id"
				>
				</el-date-picker>
			</div>
			<div class="form-item flex flex-center" v-if="showRemark">
				<div class="form-label">
					<span class="must">*</span>
					备注原因
				</div>
				<el-input
					type="textarea"
					class="flex-1"
					v-model="form.remarkReason"
					size="small"
					maxlength="255"
					show-word-limit
				></el-input>
			</div>
			<div class="text-center m-t-16 width100">
				<el-button size="small" @click="goback">取消</el-button>
				<el-button type="primary" size="small" @click="confirm" class="m-l-90">保存</el-button>
			</div>
		</div>

		<addHotelList
			:visible.sync="addHotelListVisible"
			:relationHotelInfoList.sync="form.relationHotelInfoList"
			:minipHotelList="minipHotelList"
		></addHotelList>

		<relation-hotel-list
			:visible.sync="relationVisible"
			:relationHotelInfoList.sync="form.relationHotelInfoList"
		></relation-hotel-list>
	</div>
</template>

<script>
import moment from "moment";
import { get_minip_org_list, get_minip_detail, add_minip, edit_minip, get_minip_hotel_list, edit_minipPre } from "@/api/miniprogram";
import { post_getSaleUserList } from "@/api/product";
import { get_key_value } from "@/api/common";
import { FormValid } from "@/utils/formValid";
import addHotelList from "./addHotelList";
import relationHotelList from "./relation-hotel-list";

const form = {
	id: "",
	name: "",
	shortName: "",
	orgId: "",
	relationIdList: [],
	relationHotelInfoList: [],
	appId: "",
	appSecret: "",
	status: "2", // 默认营业中
	firstPrice: "",
	yearPrice: "",
	salerId: "",
	functionCodeList: [],
	enableTime: "",
	expireTime: "",
	remarkReason: "",
	testFlag: false,
  programType: 1
};
export default {
	components: {
		addHotelList,
		relationHotelList,
	},
	data() {
		return {
			form: this.$deepClone(form),
			formOld: {},
			statusOption: [],
			functionOption: [], // 功能模块
			salerList: [],
			saler100List: [],
			selectLoading: false,
			minipHotelList: [],
			loading: false,
			detailLoading: true,
			chooseOrgList: [],
			chooseOrgType: "",
			orgHotelList: [],
			orgBrandList: [],
			checkAll: false,
			isIndeterminate: true,
			addHotelListVisible: false,
			relationVisible: false,
			isMarking: false,
      roleCheck: []
		};
	},
	computed: {
		showOrgBrandList() {
			let arr = this.orgBrandList.concat();
			// 如果机构下线或者异常状态 则需手动把机构信息加入级联选择器
			if (this.form.id && this.formOld.orgType == 2 && [3, 4].includes(this.formOld.orgStatus)) {
				arr.unshift({
					id: this.formOld.orgId,
					hotelName: this.formOld.orgShortName || this.formOld.orgName,
				});
			}
			return arr;
		},
		showOrgHotelList() {
			let arr = this.orgHotelList.concat();
			if (this.form.id && this.formOld.orgType == 1 && [3, 4].includes(this.formOld.orgStatus)) {
				arr.unshift({
					id: this.formOld.orgId,
					hotelName: this.formOld.orgShortName || this.formOld.orgName,
				});
			}
			return arr;
		},
		options() {
			return [
				{
					hotelName: "集团",
					id: "2",
					children: this.showOrgBrandList,
				},
				{
					hotelName: "门店",
					id: "1",
					children: this.showOrgHotelList,
				},
			];
		},
		props() {
			return {
				label: "hotelName",
				value: "id",
				expandTrigger: "hover",
				disabled: "disable",
			};
		},
		pickerOptions() {
			return {
				disabledDate: (date) => {
					return moment(date).isBefore(moment(), "day");
				},
			};
		},
		showRemark() {
			if (this.form.id) {
				if (this.formOld.enableTime != this.form.enableTime || this.formOld.status != this.form.status) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},
		checkFormList() {
			let arr = [
				{
					label: "小程序名称",
					value: "name",
					methods: ["notEmpty"],
				},
				{
					label: "所属机构",
					value: "orgId",
					methods: ["notEmpty"],
				},
				{
					label: "关联门店",
					value: "relationHotelInfoList",
					methods: ["notEmpty"],
				},
				{
					label: "AppId",
					value: "appId",
					methods: ["notEmpty"],
				},
				{
					label: "AppSecret",
					value: "appSecret",
					methods: ["notEmpty"],
				},
				{
					label: "状态",
					value: "status",
					methods: ["notEmpty"],
				},
				{
					label: "功能模块",
					value: "functionCodeList",
					methods: ["notEmpty"],
				},
				{
					label: "初装费",
					value: "firstPrice",
					methods: ["notEmpty"],
				},
				// {
				// 	label: "年费",
				// 	value: "yearPrice",
				// 	methods: ["notEmpty"],
				// },
				{
					label: "所属直销",
					value: "salerId",
					methods: ["notEmpty"],
				},
				{
					label: "启用时间",
					value: "enableTime",
					methods: ["notEmpty"],
				},
				{
					label: "到期时间",
					value: "expireTime",
					methods: ["notEmpty"],
				},
			];
			if (this.showRemark) {
				arr = arr.concat([
					{
						label: "备注原因",
						value: "remarkReason",
						methods: ["notEmpty"],
					},
				]);
			}
			return arr;
		},
	},
	watch: {
		chooseOrgList: {
			deep: true,
			handler(c) {
				console.log(c);
			},
		},
		"form.orgId": {
			deep: true,
			handler() {
				this.getMinipHotelList();
			},
		},
	},
	created() {
		this.detailLoading = true;
	},
	async mounted() {
		if (this.$route.query.id) {
			this.form.id = this.$route.query.id;
			await this.getOption();
			await this.getDetail();
		} else {
			this.form.id = null;
			await this.getOption();
			this.form.functionCodeList = ["CONTENT_MARKETING"];
			this.detailLoading = false;
			await this.getSaler();
		}
	},
	methods: {
		goback() {
			const keepAlive = this.$store.state.page.keepAlive.concat(["customMinip-list"]);
			this.$store.commit("page/SET_KEEPALIVE", keepAlive);
			this.$router.replace({
				name: "customMinip-list",
			});
		},
		gobackwithReset() {
			this.$store.commit("page/SET_KEEPALIVE", []);
			this.$router.replace({
				name: "customMinip-list",
			});
		},
		getOption() {
			get_key_value({
				type: "HOTEL_STATUS",
			}).then((res) => {
				this.statusOption = (res ? res.records : []).filter((o) => {
					return o.flag;
				});
			});
			get_key_value({
				type: "MINI_PROGRAM_FUNCTION",
			}).then((res) => {
				this.functionOption = (res ? res.records : [])
					.filter((o) => {
						return o.flag;
					})
					.map((v) => {
						return {
							...v,
							disabled: v.k == "CONTENT_MARKETING" ? true : false,
						};
					});
			});
			get_minip_org_list({
				page: 1,
				size: 100000,
				orgType: 1,
				id: this.form.id || "",
        programType: 1,
			}).then((res) => {
				this.orgHotelList = (res.records || []).map((o) => {
					return {
						...o,
						hotelName: o.hotelShortName || o.hotelName,
						orgType: 1,
					};
				});
			});
			get_minip_org_list({
				page: 1,
				size: 100000,
				orgType: 2,
				id: this.form.id || "",
        programType: 1,
			}).then((res) => {
				this.orgBrandList = (res.records || []).map((o) => {
					return {
						...o,
						hotelName: o.hotelShortName || o.hotelName,
						orgType: 2,
					};
				});
			});
		},
		async getSaler(search) {
			const res = await post_getSaleUserList({
				search,
				size: 100,
				pageNo: 1,
				status: this.$route.query.id ? "" : "1",
			});
			this.selectLoading = false;
			if (this.$route.query.id) {
				console.log(this.form.salerId, 555);
				let _arr = [];
				res.records.map((o) => {
					if (o.status === 1) {
						_arr.push(o);
					}
					if (this.form.salerId === o.userId) {
						if (o.status === 2) {
							_arr.push(o);
						}
					}
				});
				this.salerList = _arr;
			} else {
				this.salerList = res ? res.records : [];
			}
			if (!search) {
				this.saler100List = this.salerList.concat();
			}
		},
		remoteMethod(query) {
			if (query) {
				this.selectLoading = true;
				setTimeout(() => {
					this.getSaler(query.toLowerCase());
				}, 200);
			} else {
				this.salerList = this.saler100List.concat();
			}
		},
		getMinipHotelList() {
			get_minip_hotel_list({
				orgId: this.form.orgId,
				id: this.form.id || "",
        programType: 1,
			}).then((res) => {
				this.minipHotelList = (res || []).filter((v) => {
					return !v.disable;
				});
			});
		},
		handleChangeOrg(value) {
			console.log(value);
			if (!value[1]) return;
			this.form.orgId = value[1];
			this.chooseOrgType = value[0];
			if (this.chooseOrgType == 1) {
				const item = this.showOrgHotelList.find((o) => {
					return o.id == this.form.orgId;
				});
				this.form.relationHotelInfoList = item ? [item] : [];
			} else {
				this.form.relationHotelInfoList = [];
			}
		},
		getDetail() {
			get_minip_detail({
				id: this.form.id,
			})
				.then((res) => {
					this.form = Object.assign(this.form, res);
					this.form.status = this.form.status + "";
					this.formOld = this.$deepClone(this.form);
					this.chooseOrgList = [];
					this.chooseOrgList.push(this.form.orgType + "");
					this.chooseOrgList.push(this.form.orgId);
					this.chooseOrgType = this.form.orgType + "";
					this.getSaler();
          console.log(this.form.functionCodeList,22)
					if (this.form.functionCodeList.indexOf("MARKETING_ACTIVITIES") !== -1) {
						this.isMarking = true;
					} else {
						this.isMarking = false;
					}
          if(this.form.functionCodeList.length === 3) {
            this.checkAll = true
            this.isIndeterminate = false
          } else {
            this.checkAll = false
            this.isIndeterminate = true
          }
					this.detailLoading = false;
				})
				.catch(() => {
					this.detailLoading = false;
				});
		},
		handleCheckAllChange(value) {
      console.log(value)
			this.isIndeterminate = false;
			if (value) {
				this.form.functionCodeList = this.functionOption.map((o) => {
					return o.k;
				});
			} else {
        this.$confirm(
					"取消勾选营销活动模块后，后台所有相关活动模块功能与小程序相关活动功能均不再显示，请确认是否取消勾选？",
					"取消勾选提醒",
					{
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						customClass: "dialog-class",
						center: true,
					}
				)
					.then(() => {
						this.form.functionCodeList = ["CONTENT_MARKETING"];
            this.isMarking = false
            this.isIndeterminate = true
					})
					.catch(() => {
            this.isMarking = true
            this.checkAll = true
            this.form.functionCodeList = ["CONTENT_MARKETING","CUSTOMER_INTERACTION","MARKETING_ACTIVITIES"];
					});
			}
		},
		handleCheckChange() {
      this.form.functionCodeList = [...new Set(this.form.functionCodeList)]; //利用了Set结构不能接收重复数据的特点
      if (this.form.functionCodeList.length == this.functionOption.length) {
        this.checkAll = true;
        this.isIndeterminate = false;
      } else if (this.form.functionCodeList.length == 0) {
        this.checkAll = false;
        this.isIndeterminate = false;
      } else {
        this.checkAll = false;
        this.isIndeterminate = true;
      }
      if(this.form.functionCodeList.indexOf("MARKETING_ACTIVITIES") !== -1) {
        this.isMarking = true
      }
		},
    chooseItem(event, val) {
      console.log(val,11)
      if (event) {
        this.roleCheck.push(val.id);
      } else {
        if(val.k === "MARKETING_ACTIVITIES") {
          this.$confirm(
					"取消勾选营销活动模块后，后台所有相关活动模块功能与小程序相关活动功能均不再显示，请确认是否取消勾选？",
					"取消勾选提醒",
					{
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						customClass: "dialog-class",
						center: true,
					}
				)
					.then(() => {
            this.roleCheck.splice(this.roleCheck.indexOf(val.id), 1);
						if (this.form.functionCodeList.length == 0) {
							this.checkAll = false;
							this.isIndeterminate = false;
						} else {
							this.checkAll = false;
							this.isIndeterminate = true;
						}
					})
					.catch(() => {
            this.form.functionCodeList.push('MARKETING_ACTIVITIES')
            console.log(this.form.functionCodeList)
					});
        }
      }
    },
		handleInput(val) {
			this.form[val] = this.form[val].replace(/[^\d]/g, "");
		},
		deleteItem(index) {
			this.form.relationHotelInfoList.splice(index, 1);
		},
		checkForm() {
			const formValid = new FormValid();
			for (const f of this.checkFormList) {
				const { label, value, methods } = f;
				const v = this.form[value];
				formValid.add(label, v, methods);
			}
			const res = formValid.start();
			if (res !== true) {
				this.$message.warning(res);
				return false;
			}
			if (this.form.shortName.length > 10) {
				this.$message.warning("小程序简称长度不可大于10位");
				return false;
			}
			if (this.form.testFlag === "") {
				this.$message.warning("请选择是否为测试小程序");
				return false;
			}
			if (this.form.enableTime == moment().format("YYYY-MM-DD") && this.form.status == 1) {
				this.$message.warning("当前选择的开户时间是今天，请更改小程序状态为营业中");
				return false;
			}
			return {
				...this.form,
				relationIdList: this.form.relationHotelInfoList.map((o) => {
					return o.id;
				}),
			};
		},
		checkUnbind(params) {
			if (params.id) {
				let isUnbind = false;
				this.formOld.relationHotelInfoList.map((o) => {
					if (params.relationIdList.indexOf(o.id) < 0) {
						isUnbind = true;
					}
				});
				return isUnbind;
			}
			return false;
		},
		checkBind(params) {
			if (params.orgType == 2) {
				return true;
			}
			return false;
		},
		async confirm() {
			let params = this.checkForm();
      params.programType = 1
			if (!params) return;
			console.log(params);
			const isUnbind = this.checkUnbind(params);
			if (isUnbind) {
				const confirm = await this.$confirm(
					"小程序关联的门店解绑后，相关配置项及小程序端关于该门店的内容会无法正常显示，请确认是否要解除关联？",
					"提示",
					{
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						customClass: "dialog-class",
						center: true,
					}
				);
				if (!confirm) {
					return;
				}
			}
			const isBind = this.checkBind(params);
			if (isBind && this.minipHotelList.length - params.relationIdList.length > 0) {
				const confirm = await this.$confirm(
					`小程序已关联集团内的${params.relationIdList.length}家门店，剩余未关联门店${this.minipHotelList.length -
						params.relationIdList.length}家，请确认是否保存？`,
					"提示",
					{
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						customClass: "dialog-class",
						center: true,
					}
				);
				if (!confirm) {
					return;
				}
			}
			this.loading = true;
			if (this.form.id) {
				delete params.expireTime;
				delete params.relationHotelInfoList;
				if (!this.showRemark) {
					delete params.remarkReason;
				}
        edit_minipPre(params)
        .then(res => {
          // console.log(res)
          if(res) {
            this.$confirm(res, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              customClass: 'dialog-class',
              center: true
            }).then(() => {
              edit_minip(params)
              .then(() => {
                this.loading = false;
                this.$message.success("保存成功");
                this.gobackwithReset();
              })
              .catch(() => {
                this.loading = false;
              });
            }).catch(() => {
              this.loading = false;
              this.getDetail()
            });
          } else {
            edit_minip(params)
            .then(() => {
              this.loading = false;
              this.$message.success("保存成功");
              this.gobackwithReset();
            })
            .catch(() => {
              this.loading = false;
            });
          }
        })
			} else {
				delete params.id;
				delete params.remarkReason;
				add_minip(params)
					.then(() => {
						this.loading = false;
						this.$message.success("保存成功");
						this.gobackwithReset();
					})
					.catch(() => {
						this.loading = false;
					});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.icon-hotel {
	background: url("../../../../assets/icons/icon-hotel.png");
	width: 14px;
	height: 14px;
	display: inline-block;
	vertical-align: -1px;
	margin-right: 8px;
}
.hotel-title {
	color: #5390ff;
	font-size: 18px;
	margin-bottom: 20px;
}
.flex-form {
	max-width: 1000px;
}
.form-item {
	margin-bottom: 16px;
	width: 45%;
	margin-right: 5%;
	.must {
		color: #e14b38;
	}
}
.form-label {
	padding-right: 20px;
	text-align: right;
	width: 130px;
}
.width100 {
	width: 100%;
}
.m-l-90 {
	margin-left: 90px;
}
.c-a4 {
	color: #a4a4a4;
}
.hotel-item {
	border: 1px solid #e4e4e4;
	border-radius: 5px;
	padding: 10px;
	margin-bottom: 10px;
	&.disabled {
		background: #e4e4e4;
		cursor: not-allowed;
	}
}
.icon-warning-outline {
	background: url("../../../../assets/icons/icon-warning-outline.png");
	display: inline-block;
	width: 12px;
	height: 12px;
	vertical-align: -1px;
}
</style>
