<template>
  <div class="page-content">
    <el-tabs v-model="activeName" @tab-click="changeTab">
      <el-tab-pane label="开户信息" name="detail">
        <customDetail></customDetail>
      </el-tab-pane>
      <el-tab-pane label="授权及版本信息" name="version" v-if="$route.query.id">
        <version type="custom"></version>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import customDetail from './component/minip-edit/custom-detail.vue'
import version from './component/minip-edit/version.vue'

export default {
  name: 'minip-edit',
  components: {
    customDetail,
    version
  },
  data() {
    return {
      activeName: 'detail'
    }
  },
  computed: {
    
  },
  watch: {
  },
  mounted() {
    if (this.$route.query.from == 'auth') {
      this.activeName = 'version'
    }
  },
  methods: {
    changeTab() {},
  }
}
</script>

<style lang="scss" scoped>

</style>